import "bootstrap";
import $ from 'jquery'
import Swiper from 'swiper';

if ($('.round-menu').length > 0){
    homeStartAnimation();
}

var bluestep = false;
$("#prev-arrow").on('click', function (){
    bluestep = !bluestep;
    if (bluestep){
        $('.round-menu').eq(0).addClass('disabled');
        $('.round-menu').eq(1).removeClass('disabled');
        $('#green-bg').addClass('active');
    }else{
        $('.round-menu').eq(0).removeClass('disabled');
        $('.round-menu').eq(1).addClass('disabled');
        $('#green-bg').removeClass('active');
    }
});
$("#next-arrow").on('click', function (){
    $("#prev-arrow").click();
});

function homeStartAnimation(){
    var rotation1 = 0;
    var rotation2 = 0;
    $('.round-menu').eq(0).find('.round-menu-item').each(function (){
        var new_rot = rotation1+$(this).index()*60;
        $(this).css({'transform': 'rotate('+new_rot+'deg)'})
        $(this).find('.round-menu-item-inner').css({'transform': 'rotate(-'+(rotation1+$(this).index()*60)+'deg)'})
        $(this).find('.round-menu-item-inner-texts').find('span').removeClass('show');
        if (new_rot > 270 || new_rot < 90 ){
            $(this).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
        }else{
            $(this).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
        }
    });
    var defaultRotate1 = setInterval(function (){
        if (!bluestep){
            rotation1+=1;
            if (rotation1 == 360){
                rotation1 = 0;
                $('.round-menu').eq(0).find('.round-menu-item').removeClass('transit');
            }
            setTimeout(function (){
                $('.round-menu').eq(0).find('.round-menu-item').each(function (){
                    var new_rot = rotation1+$(this).index()*60;
                    $(this).css({'transform': 'rotate('+new_rot+'deg)'})
                    $(this).find('.round-menu-item-inner').css({'transform': 'rotate(-'+(rotation1+$(this).index()*60)+'deg)'})
                    $(this).find('.round-menu-item-inner-texts').find('span').removeClass('show');
                    if (new_rot % 360 > 270 || new_rot % 360 < 90){
                        $(this).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
                    }else{
                        $(this).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
                    }
                });
                $('.round-menu').find('.round-menu-item').addClass('transit');
                $('#green-bg').addClass('transit');
                $('.round-menu').addClass('transit');
            },10)
        }


    }, 300)

    $('.round-menu').eq(1).find('.round-menu-item').each(function (){
        var new_rot = rotation2+$(this).index()*72;
        $(this).css({'transform': 'rotate('+new_rot+'deg)'})
        $(this).find('.round-menu-item-inner').css({'transform': 'rotate(-'+(rotation2+$(this).index()*72)+'deg)'})
        $(this).find('.round-menu-item-inner-texts').find('span').removeClass('show');
        if (new_rot > 270 || new_rot < 90 ){
            $(this).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
        }else{
            $(this).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
        }
    });

    var defaultRotate2 = setInterval(function (){
        if (bluestep){
            rotation2+=1;
            if (rotation2 == 360){
                rotation2 = 0;
                $('.round-menu').eq(1).find('.round-menu-item').removeClass('transit');
            }
            setTimeout(function (){
                $('.round-menu').eq(1).find('.round-menu-item').each(function (){
                    var new_rot = rotation2+$(this).index()*72;
                    $(this).css({'transform': 'rotate('+new_rot+'deg)'})
                    $(this).find('.round-menu-item-inner').css({'transform': 'rotate(-'+(rotation2+$(this).index()*72)+'deg)'})
                    $(this).find('.round-menu-item-inner-texts').find('span').removeClass('show');
                    if (new_rot % 360 > 270 || new_rot % 360 < 90){
                        $(this).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
                    }else{
                        $(this).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
                    }
                });
            },10)
        }
    }, 300)

    $('.round-menu').eq(0).find('.round-menu-item').on('click', function (){
        clearInterval(defaultRotate1);
        var par = $('.round-menu').eq(0);
        par.find('.round-menu-item').removeClass('transit');
        par.find('.round-menu-item').addClass('transitEase');
        par.find('.round-menu-item').removeClass('active');
        $(this).addClass('active');
        if ($(this).index() == 0){
            par.find('.round-menu-item').eq(0).css({'transform': 'rotate(-37deg)'});
            par.find('.round-menu-item').eq(1).css({'transform': 'rotate(110deg)'});
            par.find('.round-menu-item').eq(2).css({'transform': 'rotate(147deg)'});
            par.find('.round-menu-item').eq(3).css({'transform': 'rotate(179deg)'});
            par.find('.round-menu-item').eq(4).css({'transform': 'rotate(211deg)'});
            par.find('.round-menu-item').eq(5).css({'transform': 'rotate(244deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner').css({'transform': 'rotate(37deg)'});
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner').css({'transform': 'rotate(-110deg)'});
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner').css({'transform': 'rotate(-147deg)'});
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner').css({'transform': 'rotate(-179deg)'});
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner').css({'transform': 'rotate(-211deg)'});
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner').css({'transform': 'rotate(-244deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
        }else if ($(this).index() == 1){
            par.find('.round-menu-item').eq(1).css({'transform': 'rotate(-37deg)'});
            par.find('.round-menu-item').eq(2).css({'transform': 'rotate(110deg)'});
            par.find('.round-menu-item').eq(3).css({'transform': 'rotate(147deg)'});
            par.find('.round-menu-item').eq(4).css({'transform': 'rotate(179deg)'});
            par.find('.round-menu-item').eq(5).css({'transform': 'rotate(211deg)'});
            par.find('.round-menu-item').eq(0).css({'transform': 'rotate(-117deg)'});
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner').css({'transform': 'rotate(37deg)'});
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner').css({'transform': 'rotate(-110deg)'});
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner').css({'transform': 'rotate(-147deg)'});
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner').css({'transform': 'rotate(-179deg)'});
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner').css({'transform': 'rotate(-211deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner').css({'transform': 'rotate(117deg)'});
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
        }else if ($(this).index() == 2){
            par.find('.round-menu-item').eq(0).css({'transform': 'rotate(26deg)'});
            par.find('.round-menu-item').eq(1).css({'transform': 'rotate(59deg)'});
            par.find('.round-menu-item').eq(2).css({'transform': 'rotate(220deg)'});
            par.find('.round-menu-item').eq(3).css({'transform': 'rotate(291deg)'});
            par.find('.round-menu-item').eq(4).css({'transform': 'rotate(324deg)'});
            par.find('.round-menu-item').eq(5).css({'transform': 'rotate(353deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner').css({'transform': 'rotate(-26deg)'});
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner').css({'transform': 'rotate(-59deg)'});
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner').css({'transform': 'rotate(-220deg)'});
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner').css({'transform': 'rotate(-291deg)'});
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner').css({'transform': 'rotate(-324deg)'});
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner').css({'transform': 'rotate(-353deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
        }else if ($(this).index() == 3){
            par.find('.round-menu-item').eq(0).css({'transform': 'rotate(-8deg)'});
            par.find('.round-menu-item').eq(1).css({'transform': 'rotate(23deg)'});
            par.find('.round-menu-item').eq(2).css({'transform': 'rotate(59deg)'});
            par.find('.round-menu-item').eq(3).css({'transform': 'rotate(220deg)'});
            par.find('.round-menu-item').eq(4).css({'transform': 'rotate(291deg)'});
            par.find('.round-menu-item').eq(5).css({'transform': 'rotate(324deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner').css({'transform': 'rotate(8deg)'});
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner').css({'transform': 'rotate(-23deg)'});
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner').css({'transform': 'rotate(-59deg)'});
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner').css({'transform': 'rotate(-220deg)'});
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner').css({'transform': 'rotate(-291deg)'});
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner').css({'transform': 'rotate(-324deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
        }else if ($(this).index() == 4){
            par.find('.round-menu-item').eq(1).css({'transform': 'rotate(-4deg)'});
            par.find('.round-menu-item').eq(2).css({'transform': 'rotate(26deg)'});
            par.find('.round-menu-item').eq(3).css({'transform': 'rotate(59deg)'});
            par.find('.round-menu-item').eq(4).css({'transform': 'rotate(220deg)'});
            par.find('.round-menu-item').eq(5).css({'transform': 'rotate(291deg)'});
            par.find('.round-menu-item').eq(0).css({'transform': 'rotate(-33deg)'});
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner').css({'transform': 'rotate(4deg)'});
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner').css({'transform': 'rotate(-26deg)'});
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner').css({'transform': 'rotate(-59deg)'});
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner').css({'transform': 'rotate(-220deg)'});
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner').css({'transform': 'rotate(-291deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner').css({'transform': 'rotate(33deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
        }else if ($(this).index() == 5){
            par.find('.round-menu-item').eq(5).css({'transform': 'rotate(323deg)'});
            par.find('.round-menu-item').eq(4).css({'transform': 'rotate(243deg)'});
            par.find('.round-menu-item').eq(0).css({'transform': 'rotate(110deg)'});
            par.find('.round-menu-item').eq(1).css({'transform': 'rotate(147deg)'});
            par.find('.round-menu-item').eq(2).css({'transform': 'rotate(179deg)'});
            par.find('.round-menu-item').eq(3).css({'transform': 'rotate(211deg)'});
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner').css({'transform': 'rotate(-323deg)'});
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner').css({'transform': 'rotate(-243deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner').css({'transform': 'rotate(-110deg)'});
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner').css({'transform': 'rotate(-147deg)'});
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner').css({'transform': 'rotate(-179deg)'});
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner').css({'transform': 'rotate(-211deg)'});
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(5).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
        }
    });

    $('.round-menu').eq(1).find('.round-menu-item').on('click', function (){
        clearInterval(defaultRotate2);
        var par = $('.round-menu').eq(1);
        par.find('.round-menu-item').removeClass('transit');
        par.find('.round-menu-item').addClass('transitEase');
        par.find('.round-menu-item').removeClass('active');
        $(this).addClass('active');
        if ($(this).index() == 0){
            par.find('.round-menu-item').eq(0).css({'transform': 'rotate(-37deg)'});
            par.find('.round-menu-item').eq(1).css({'transform': 'rotate(110deg)'});
            par.find('.round-menu-item').eq(2).css({'transform': 'rotate(147deg)'});
            par.find('.round-menu-item').eq(3).css({'transform': 'rotate(179deg)'});
            par.find('.round-menu-item').eq(4).css({'transform': 'rotate(211deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner').css({'transform': 'rotate(37deg)'});
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner').css({'transform': 'rotate(-110deg)'});
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner').css({'transform': 'rotate(-147deg)'});
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner').css({'transform': 'rotate(-179deg)'});
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner').css({'transform': 'rotate(-211deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
        }else if ($(this).index() == 1){
            par.find('.round-menu-item').eq(1).css({'transform': 'rotate(-37deg)'});
            par.find('.round-menu-item').eq(2).css({'transform': 'rotate(110deg)'});
            par.find('.round-menu-item').eq(3).css({'transform': 'rotate(147deg)'});
            par.find('.round-menu-item').eq(4).css({'transform': 'rotate(179deg)'});
            par.find('.round-menu-item').eq(0).css({'transform': 'rotate(-117deg)'});
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner').css({'transform': 'rotate(37deg)'});
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner').css({'transform': 'rotate(-110deg)'});
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner').css({'transform': 'rotate(-147deg)'});
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner').css({'transform': 'rotate(-179deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner').css({'transform': 'rotate(117deg)'});
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
        }else if ($(this).index() == 2){
            par.find('.round-menu-item').eq(0).css({'transform': 'rotate(26deg)'});
            par.find('.round-menu-item').eq(1).css({'transform': 'rotate(59deg)'});
            par.find('.round-menu-item').eq(2).css({'transform': 'rotate(220deg)'});
            par.find('.round-menu-item').eq(3).css({'transform': 'rotate(308deg)'});
            par.find('.round-menu-item').eq(4).css({'transform': 'rotate(353deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner').css({'transform': 'rotate(-26deg)'});
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner').css({'transform': 'rotate(-59deg)'});
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner').css({'transform': 'rotate(-220deg)'});
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner').css({'transform': 'rotate(-308deg)'});
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner').css({'transform': 'rotate(-353deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
        }else if ($(this).index() == 3){
            par.find('.round-menu-item').eq(0).css({'transform': 'rotate(-8deg)'});
            par.find('.round-menu-item').eq(1).css({'transform': 'rotate(23deg)'});
            par.find('.round-menu-item').eq(2).css({'transform': 'rotate(59deg)'});
            par.find('.round-menu-item').eq(3).css({'transform': 'rotate(220deg)'});
            par.find('.round-menu-item').eq(4).css({'transform': 'rotate(308deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner').css({'transform': 'rotate(8deg)'});
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner').css({'transform': 'rotate(-23deg)'});
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner').css({'transform': 'rotate(-59deg)'});
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner').css({'transform': 'rotate(-220deg)'});
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner').css({'transform': 'rotate(-308deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
        }else if ($(this).index() == 4){
            par.find('.round-menu-item').eq(4).css({'transform': 'rotate(323deg)'});
            par.find('.round-menu-item').eq(0).css({'transform': 'rotate(120deg)'});
            par.find('.round-menu-item').eq(1).css({'transform': 'rotate(160deg)'});
            par.find('.round-menu-item').eq(2).css({'transform': 'rotate(205deg)'});
            par.find('.round-menu-item').eq(3).css({'transform': 'rotate(243deg)'});
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner').css({'transform': 'rotate(-323deg)'});
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner').css({'transform': 'rotate(-120deg)'});
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner').css({'transform': 'rotate(-160deg)'});
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner').css({'transform': 'rotate(-205deg)'});
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner').css({'transform': 'rotate(-243deg)'});
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(0).removeClass('show');
            par.find('.round-menu-item').eq(4).find('.round-menu-item-inner-texts').find('span').eq(1).addClass('show');
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(0).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(1).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(2).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(1).removeClass('show');
            par.find('.round-menu-item').eq(3).find('.round-menu-item-inner-texts').find('span').eq(0).addClass('show');
        }
    });
}
